<template>
  <div>
    <b-card class="p-2 mb-2" no-body>
        <b-row>        
            <b-col class="app-width-max-250 app-width-min-250">  
                <b-button block variant="outline-secondary" class="app-height-min-60 m-2" @click="getUsersList()">
                    <b-icon icon="person-lines-fill" class="btn-icon-size"></b-icon>
                    <span class="pl-3 align-middle">{{trans('users',167)}}</span>
                </b-button> 
            </b-col>
            
            <b-col class="app-width-max-250 app-width-min-250">
                <b-button block variant="outline-secondary" class="app-height-min-60 m-2" @click="getOfficesList()">
                    <b-icon icon="building" class="btn-icon-size"></b-icon>
                    <span class="pl-3 align-middle">{{trans('settings-offices',207)}}</span>
                </b-button> 
            </b-col>

            <b-col class="app-width-max-250 app-width-min-250">
                <b-button block variant="outline-secondary" class="app-height-min-60 m-2" @click="getRolesList()">
                    <b-icon icon="building" class="btn-icon-size"></b-icon>
                    <span class="pl-3 align-middle">{{trans('settings-roles',207)}}</span>
                </b-button> 
            </b-col>
           
            <b-col> </b-col>
        </b-row>    
    </b-card>
    <b-card class="p-2 mb-2" no-body>
        <b-row>        
            <b-col class="app-width-max-250 app-width-min-250">  
                <b-button block variant="outline-secondary" class="app-height-min-60 m-2 " @click="getDocumentsList()">
                    <b-icon icon="file-earmark-text-fill" class="btn-icon-size"></b-icon>
                    <span class="pl-3 align-middle">{{trans('settings-document-templates',167)}}</span>
                </b-button> 
            </b-col>

            <b-col class="app-width-max-250 app-width-min-250">  
                <b-button block variant="outline-secondary" class="app-height-min-60 m-2 " @click="getVariablesList()">
                    <b-icon icon="hdd-stack-fill" class="btn-icon-size"></b-icon>
                    <span class="pl-3 align-middle">{{trans('settings-system-variables',207)}}</span>
                </b-button> 
            </b-col>     

            <b-col class="app-width-max-250 app-width-min-250">  
                <b-button block variant="outline-secondary" class="app-height-min-60 m-2 " @click="getSectionsList()">
                    <b-icon icon="hdd-stack-fill" class="btn-icon-size"></b-icon>
                    <span class="pl-3 align-middle">{{trans('cao',182)}}</span>
                </b-button> 
            </b-col>    

            <b-col class="app-width-max-250 app-width-min-250">  
                <b-button block variant="outline-secondary" class="app-height-min-60 m-2 " @click="getTypesList()">
                    <b-icon icon="hdd-stack-fill" class="btn-icon-size"></b-icon>
                    <span class="pl-3 align-middle">{{trans('settings-functions',182)}}</span>
                </b-button> 
            </b-col>    

            <b-col class="app-width-max-250 app-width-min-250">  
                <b-button block variant="outline-secondary" class="app-height-min-60 m-2 " @click="getFreeDaysList()">
                    <b-icon icon="calendar2-day-fill" class="btn-icon-size"></b-icon>
                    <span class="pl-4 align-middle">{{trans('settings-national-holidays',167)}}</span>
                </b-button> 
            </b-col>       

            <b-col class="app-width-max-250 app-width-min-250">  
                <b-button block variant="outline-secondary" class="app-height-min-60 m-2 " @click="getTranslationsList()">
                    <b-icon icon="textarea-t" class="btn-icon-size"></b-icon>
                    <span class="pl-4 align-middle">{{trans('settings-dictionary',167)}}</span>
                </b-button> 
            </b-col>     
            
            <b-col class="app-width-max-250 app-width-min-250">
                <b-button block variant="outline-secondary" class="app-height-min-60 m-2" @click="getEmailsSentList()">
                    <b-icon icon="mailbox2" class="btn-icon-size"></b-icon>
                    <span class="pl-3 align-middle">{{trans('sent-emails',207)}}</span>
                </b-button> 
            </b-col>  

            <b-col class="app-width-max-250 app-width-min-250">
                <b-button block variant="outline-secondary" class="app-height-min-60 m-2" @click="getDocumentsReport()">
                    <b-icon icon="file-earmark-spreadsheet-fill" class="btn-icon-size"></b-icon>
                    <span class="pl-3 align-middle">{{trans('settings-documents-report', 167)}}</span>
                </b-button> 
            </b-col> 
           
            <b-col> </b-col>
        </b-row>    
    </b-card>
    
    <b-card class="p-2 mb-2" no-body>
        <b-row>   

             <b-col class="app-width-max-250 app-width-min-250">
                <b-button block variant="outline-secondary" class="app-height-min-60 m-2" @click="getEasyflexList()">
                    <b-icon icon="arrow-left-right" class="btn-icon-size"></b-icon>
                    <span class="pl-3 align-middle">{{trans('settings-easyflex',167)}}</span>
                </b-button> 
            </b-col>

            <b-col class="app-width-max-250 app-width-min-250">  
                <b-button block variant="outline-secondary" class="app-height-min-60 m-2" @click="getApiList()">
                    <b-icon icon="arrows-angle-contract" class="btn-icon-size"></b-icon>
                    <span class="pl-3 align-middle">{{trans('settings-api',167)}}</span>
                </b-button> 
            </b-col>
            
            <!--<b-col class="app-width-max-250 app-width-min-250">
                <b-button block variant="outline-secondary" class="app-height-min-60 m-2">
                    <b-icon icon="bullseye" class="btn-icon-size"></b-icon>
                    <span class="pl-3 align-middle">{{trans('settings-development',167)}}</span>
                </b-button> 
            </b-col> -->  

            <b-col class="app-width-max-250 app-width-min-250">
                <b-button block variant="outline-secondary" class="app-height-min-60 m-2" @click="getQueueList()">
                    <b-icon icon="receipt" class="btn-icon-size"></b-icon>
                    <span class="pl-3 align-middle">{{trans('settings-queues',207)}}</span>
                </b-button> 
            </b-col>
            
           <!-- <b-col class="app-width-max-250 app-width-min-250">
                <b-button block variant="outline-secondary" class="app-height-min-60 m-2" @click="getImportData()">
                    <b-icon icon="upload" class="btn-icon-size"></b-icon>
                    <span class="pl-3 align-middle">{{trans('upload-file',207)}}</span>
                </b-button> 
            </b-col>
           -->
        </b-row>    
    </b-card> 

    <b-card class="p-2 mb-2" no-body v-if="user_role == 1">
        <b-row>
            <b-col class="app-width-max-250 app-width-min-250">
                <b-button block variant="outline-secondary" class="app-height-min-60 m-2" @click="getErrorsList()">
                    <b-icon icon="card-checklist" class="btn-icon-size"></b-icon>
                    <span class="pl-3 align-middle">{{trans('settings-error-list',207)}}</span>
                </b-button> 
            </b-col>  
            
            <b-col class="app-width-max-250 app-width-min-250">
                <b-button block variant="outline-secondary" class="app-height-min-60 m-2" @click="getEasyflexImport()">
                    <b-icon icon="download" class="btn-icon-size"></b-icon>
                    <span class="pl-3 align-middle">Import to H1</span>
                </b-button> 
            </b-col>  

        </b-row>
    </b-card>
    

  </div>
</template>
<script>
import axios from "@axios";

export default {

  data() {
        return {
            programmersList: [2, 4, 6, 147, 150, 151, 154, 155, 160, 334],
            user_id: JSON.parse(localStorage.getItem('user')).id,
            user_role: JSON.parse(localStorage.getItem('user')).id_user_const_role,
            items: [],
            loading: false
        }
    },
  
    created(){
    },
  
    methods: {
        getUsersList: function() {
            this.$router.push({ name: "users" });
        },
        getDocumentsList: function() {
            this.$router.push({ name: "documents-templates" });
        },
        getVariablesList: function(){
            this.$router.push({name: "variables"});
        },

        getSectionsList: function(){
            this.$router.push({name: "sections"});
        },

        getTypesList: function(){
            this.$router.push({name: "types"});
        },

        getFreeDaysList: function(){
            this.$router.push({name: "freeDays"});
        },

         getTranslationsList: function(){
            this.$router.push({name: "translations"});
        },
        
        getOfficesList: function() {
            this.$router.push({ name: "offices" });
        },

        getEasyflexList: function() {
            this.$router.push({ name: "easyflex" });
        },

        getApiList: function() {
            this.$router.push({ name: "api" });
        },

        getQueueList: function() {
            this.$router.push({ name: "queue" });
        },

        getEmailsSentList: function() {
            this.$router.push({ name: "emails-sent" });
        },

        getRolesList: function(){
            this.$router.push({name: 'roles'});
        },

        getErrorsList(){
            this.$router.push({name: 'errors'});
        },

        getInfiniteList(){
            this.$router.push({name: 'infinite-scroll'});
        },

        getImportData(){
            this.$router.push({name: 'import-data'});
        },

        getEasyflexImport(){
            this.$router.push({name: 'easyflex-import'});
        },

        getDocumentsReport(){
            this.$router.push({name: 'documents-report'});
        },
    }
 
};
</script>
<style scoped>
.btn-icon-size {
    min-width:30px; 
    min-height:30px;    
    position: absolute;
    left: 14px;
    top: 14px;
}

.btn-dashboard-icon-size {
    min-width:35px; 
    min-height:35px;    
    position: absolute;
    left: 23px;
    top: 15px;
    color: white;

}

.app-local-container {
    display: flex;
    flex-wrap: nowrap;  
    width: 300px  
}

.app-local-left-card {
    max-width: 80px; 
    min-width: 80px;
    border-radius: 5px 0px 0px 5px;
}

.app-local-right-card {
    flex: auto;
    border-radius: 0px 5px 5px 0px;
    padding: 4px 15px 4px 15px;
    display: flex;
    align-items: stretch;
}

.app-local-title-span {
    margin-top: 10px;
    flex: auto;    
}

.app-local-text{
    font-size: 10px;
    margin-bottom: 10px
}
</style>